window.SiteReady(($) => {
  console.log('Init: BazaarVoice');

  if (typeof THEME_OPTIONS !== 'undefined') {

    $('.js-bv_conversion').on('click', () => {
      let data = {
        'type': $(this).data('track-location'),
        'label': window.location.href,
        'value': $(this).data('track-upc')
      };

      try {
        BV.pixel.trackConversion(data);

        console.log('BazaarVoice: Track Conversion', data);
      } catch(e) {}
    });

    window.bvModalTimer = null;

    window.bvCallback = function(BV) {
      console.log('BV Callback');

      let params = (new URL(document.location)).searchParams;
      let show_modal = (params.get('review_modal') == 'true');

      if (show_modal) {
        window.bvModalTimer = setTimeout(window.bvCheckModal, 10);
      }
    };

    $(window).on('load', () => {
      // window.loadBazaarvoiceApi(function() {
      //   console.log('BazaarVoice: Loaded');
      // });

      if (window.BV) {
        // cb();
        console.log('BazaarVoice: Exists');
      } else if (typeof THEME_OPTIONS !== 'undefined' && THEME_OPTIONS.bvurl) {
        $.ajax({
          url: THEME_OPTIONS.bvurl,
          cache: true,
          dataType: 'script',
          success: function() {
            // cb();
            console.log('BazaarVoice: Loaded');
          }
        });
      }

    });

    window.bvCheckModal = () => {
      if ($('.bv-write-review').length > 0) {
        $('.bv-write-review').trigger('click');
      } else if ($('.bv_button_buttonMinimalist').length > 0) {
        $('.bv_button_buttonMinimalist').trigger('click');
      } else {
        window.bvModalTimer = setTimeout(window.bvCheckModal, 10);
      }
    }

  }

  $('.product_rating').on('click', '.bv_ratings_summary, .bv_modal_component_container .bv_button_buttonFull', (e) => {
    e.preventDefault();
    e.stopPropagation();

    $('.block_bazaarvoice .bazaarvoice_handle').swap('activate');

    setTimeout(window.scrollToElement('.block_bazaarvoice'), 5);
  });

});